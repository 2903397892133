/* eslint-disable react/no-danger */
import React from 'react'
import { string } from 'prop-types'
import { motion } from 'framer-motion'
import useMediaQuery from '@/hooks/useMediaQuery'
import AnimateEntrance from '@/utils/AnimateEntrance'
import { containerVariants, textVariants } from '@/utils/transitions'

function ImageHeader({ id, title, body, image }) {
	const isMobile = useMediaQuery('(max-width: 48em)')

	return (
		<AnimateEntrance
			variants={containerVariants}
			id={id}
			threshold={0.1}
			rootMargin="-100px 0px"
			className="relative flex flex-col lg:items-center"
		>
			{/* <motion.img
				src={image}
				alt=""
				loading="lazy"
				className="relative"
				variants={textVariants}
			/> */}
			<div className="relative">
				<video
					preload="auto"
					loop
					autoPlay
					playsInline
					muted
					src={ isMobile ? "/motion/Exploded_Leap_Diagram_MOBILE.mp4" : "/motion/Exploded_Leap_Diagram_V02.mp4"}
					className="object-cover w-full h-full"
				/>
			</div>
			<div className="max-w-2xl mx-auto text-center contents lg:block">
				<motion.h2
					variants={textVariants}
					className="mb-8 leading-tight text-center text-h2-fluid font-museoSansBold text-secondary"
				>
					{title}
				</motion.h2>
				<motion.div
					variants={textVariants}
					className="order-last w-11/12 mx-auto md:w-9/12 lg:w-7/12"
					dangerouslySetInnerHTML={{ __html: body }}
				/>
			</div>
		</AnimateEntrance>
	)
}
ImageHeader.propTypes = {
	id: string.isRequired,
	title: string.isRequired,
	body: string.isRequired,
	image: string.isRequired
}

export default ImageHeader
