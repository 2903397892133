/* eslint-disable react/no-danger */
import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'
import classNames from 'classnames'
import PageMenu from './PageMenu'
import PurpleRow from '@/components/hiw/PurpleRow'
import ContactForm from '@/components/hiw/ContactForm'
import { HeroShouter } from '@/components/HeroShouter'

import BackgroundImageText from './BackgroundImageText'
import WhiteRow from '@/components/hiw/WhiteRow'
import ImageHeader from './ImageHeader'
import W2BSplitImage from '../Where2Buy/W2BSplitImage'

export const variants = {
	initial: { opacity: 0, y: -250 },
	reveal: { opacity: 1, y: -250, transition: { duration: 0.75 } },
	place: { opacity: 1, y: 0, transition: { duration: 1.2 } }
}

function Tracking() {
	const [screen, setScreen] = useState(null)
	const [menuHidden, setMenuHidden] = useState(false);
	const whereToBuyImage = {
		source_url: "/LMC/new/lmc_04.jpg"
	}

	const handleHideMenu = (inView, intersectionObserverEntry) => {
		if (inView) {
			setScreen(null);
			setMenuHidden(true);
		} else if (intersectionObserverEntry.boundingClientRect.top > 0) {
			setScreen(6);
			setMenuHidden(false);
		}
	}

	return (
		<div>
			<div className="relative">
				<PageMenu
					className={ classNames('hidden 2xl:block') }
					screen={ screen }
					hidden={ menuHidden }
				/>

				<InView
					as="div"
					threshold={ 0.1 }
					rootMargin="-100px 0px"
					onChange={ inView => inView && setScreen(1) }
					className="relative mb-20"
					id="how-it-works"
				>
					<PurpleRow
						id="unrivalled-software"
						setScreen={ setScreen }
						index={ 1 }
						subHeading="Unrivalled software"
						title=" Your hands are intricate and nuanced. Our software is too."
						body="<p>Our hand tracking software captures all the subtlety and complexity of natural hand movements.  It’s based on a decade of development and iteration, generations of research in artificial intelligence and the feedback of hundreds of thousands of developers.</p><p>Fast, robust, accurate, and works on almost any computer. </p>"
						image="/tracking/yourhandsvirtual.png"
					/>
				</InView>
				<InView
					as="div"
					threshold={ 0.1 }
					rootMargin="-100px 0px"
					onChange={ inView => inView && setScreen(2) }
					className="relative mb-32 wrapper lg:mb-48"
				>
					<ImageHeader
						id="simple-sensors"
						title="It starts with a simple sensor that detects your hands. "
						body="<p>The heart of a hand tracking device is two cameras and some infrared LEDs. Ultraleap’s hand tracking modules the <a href='/product/leap-motion-controller'>Leap Motion Controller</a> and the <a href='/product/stereo-ir-170'>Stereo IR 170 Camera Module</a> work on this principle, as do VR/AR headsets built using <a href='/company/news/press-release/qualcomm-snapdragon-xr2'>Qualcomm’s XR2 reference designs.</a></p>"
						index={ 2 }
						image="/motion/simple-hardware.png"
					/>
				</InView>
				<InView
					as="div"
					id="hand-images"
					threshold={ 0.1 }
					rootMargin="-100px 0px"
					onChange={ inView => inView && setScreen(3) }
					className="relative lg:mb-48"
				>
					<BackgroundImageText setScreen={ setScreen } index={ 3 } />
				</InView>
				<InView
					as="div"
					threshold={ 0.1 }
					rootMargin="-100px 0px"
					onChange={ inView => inView && setScreen(4) }
					className="relative mb-32 lg:mb-48"
				>
					<PurpleRow
						id="skeletal-tracking"
						setScreen={ setScreen }
						index={ 4 }
						subHeading="Skeletal tracking"
						title="This is just the start. The real magic is in our skeletal tracking."
						body="<p>Our software uses the images to generate a virtual model of your hand movements. We model not just your palm or fingertips, but the joints and bones inside your hand. This means we can accurately predict the position of a finger or thumb, even if it’s hidden from view.</p>"
						image="/motion/UltraLeap_Product05342_edit.png"
					/>
				</InView>
				<InView
					as="div"
					threshold={ 0.1 }
					rootMargin="-100px 0px"
					onChange={ inView => inView && setScreen(5) }
					className="relative"
				>
					<WhiteRow
						id="interaction-engine"
						setScreen={ setScreen }
						index={ 5 }
						subHeading="Interaction Engine"
						title="… and our Interaction Engine."
						body="<p>The virtual models of your hand movements are fed into the Ultraleap Interaction Engine. The Interaction Engine provides a unified physical interaction paradigm. Grabbing, swiping, pushing, pinching … they all become as effortless as they are in the real world.</p>"
						video="/motion/Leap_Motion_Orion_Edit_V01.mp4"
					/>
				</InView>

				<HeroShouter
					index={ undefined }
					className="py-12 mb-18 md:py-18 xl:py-24 text-secondary lg:mb-48"
					key_word="Unparalleled"
					key_copy="Real interaction,<br/> real time"
				/>

				<InView
					as="div"
					threshold={ 0.1 }
					rootMargin="-50px 0px"
					onChange={ inView => inView && setScreen(6) }
					className="relative mb-32 lg:mb-48"
				>
					<PurpleRow
						id="real-time-interaction"
						setScreen={ setScreen }
						index={ 6 }
						subHeading="Real-time interaction"
						title="The world’s leading hand tracking.  "
						body="<p>Our software makes digital interaction human.<br />It’s so fast, so smooth, they’re just … <i>your hands.</i></p>"
						video="/motion/Cat_Explorer_Edit_V01.mp4"
					/>
				</InView>
				<InView
					as="div"
					threshold={ 0.1 }
					rootMargin="-200px 0px"
					onChange={ handleHideMenu }
					className="relative mb-32 lg:mb-48"
				>
					<W2BSplitImage
						ctatext="Buy now"
						ctalink="/product/leap-motion-controller/#pricingandlicensing"
						product="Leap Motion Controller"
						image={ whereToBuyImage }
						imageAlt="Buy our Leap Motion Controller"
					/>
				</InView>
			</div>
			<InView
				as="div"
				threshold={ 0.1 }
				rootMargin="-100px 0px"
				onChange={ inView => inView && setScreen(7) }
			>
				<ContactForm />
			</InView>
		</div>
	)
}

// Tracking.propTypes = {
// 	products: arrayOf(
// 		shape({
// 			remainingItems: arrayOf(
// 				shape({
// 					image: string.isRequired,
// 					header: string.isRequired,
// 					intro: string.isRequired,
// 					ctaOneLink: string.isRequired,
// 					ctaOneCopy: string.isRequired,
// 					ctaTwoLink: string.isRequired,
// 					ctaTwoCopy: string.isRequired
// 				})
// 			),
// 			firstItem: shape({
// 				image: string.isRequired,
// 				header: string.isRequired,
// 				intro: string.isRequired,
// 				ctaOneLink: string.isRequired,
// 				ctaOneCopy: string.isRequired,
// 				ctaTwoLink: string.isRequired,
// 				ctaTwoCopy: string.isRequired
// 			})
// 		})
// 	)
// }

export default Tracking
