/* eslint-disable react/prop-types */
import React from 'react'
import { motion } from 'framer-motion'

import AnimateEntrance from '@/utils/AnimateEntrance'
import { textVariants, containerVariants } from '@/utils/transitions'

function BackgroundImageText({ id }) {
	return (
		<AnimateEntrance
			variants={containerVariants}
			id={id}
			threshold={0.1}
			rootMargin="-100px 0px"
			className="relative lg:items-center pt-16 pb-56 md:pb-32 lg:py-0"
			style={{ minHeight: 500, backgroundColor: '#bebebe' }}
		>
			<div className="relative lg:absolute lg:inset-y-0 lg:left-0 h-full sm:site-grid grid-dense lg:row-1 lg:items-center">
				<div className="relative z-10 px-8 bg-transparent sm:pb-20 lg:px-0 sm:column-3/6 md:column-3/6 2xl:column-5/4">
					<motion.h2
						variants={textVariants}
						className="mb-8 leading-tight text-secondary text-h2-fluid font-museoSansBold"
					>
						Simple, but powerful and fast.
					</motion.h2>
					<motion.p variants={textVariants} className="san-serif">
                        The LEDs illuminate your hands with infrared light. You can't see it, but our sensors can. The LEDs pulse in sync with the camera framerate. Every time they do this the cameras send data back to the computer to track your hands. 
					</motion.p>
				</div>
			</div>
			<video
				src="/tracking/Infrared_Diagram___1200x800_v2.mp4"
				alt="hand"
				autoPlay
				loop
				muted
				className="left-0 absolute bottom-4 w-full lg:left-auto lg:right-0 lg:relative lg:ml-auto lg:bottom-0  max-w-tracking"
			/>
		</AnimateEntrance>
	)
}

BackgroundImageText.propTypes = {}

export default BackgroundImageText
